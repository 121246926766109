
import Optimization from "../assets/pictures/grup03.png";

const BachupDisaster = () => {
  return (
    <>
      {/* main1 */}
      <main className=" mt-10 container max-w-7xl mx-auto">
        {/* img and title */}
        <div className="text-center  py-2 mb-2 mt-2">
          <div className="flex justify-center items-center flex-col sm:flex-row rounded ">
            <div className="">
              <img src={Optimization} className="opacity-[0.9] max-w-[360px]" />
            </div>

            <div className=" m-10 ">
              <h2 className=" text-5xl sm:text-6xl font-bold font-sans text-teal-500 mb-4  opacity-[0.8]">
                BACKUP-DISASTER
              </h2>
              <h3 className="text-4xl sm:text-6xl text-gray-500 font-sans font-bold opacity-[0.9]">
                Ensure continuity and resilience in the face of the unexpected
              </h3>
            </div>
          </div>
          <hr className=" border-teal-400 border-[1.5px] dark:border-gray-700 "></hr>
        </div>
      </main>

      {/* main2 */}
      <main className="bg-white mb-8 mt-10">
        <main className="container max-w-7xl font-bold bg-white text-gray-600 mx-auto px-2 py-2 opacity-[0.9]">
          {/* section1 */}
          <section className=" text-xl font-bold grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8">
            <div className="p-6 rounded-lg">
              <div className="flex justify-center items-center" />

              {/* <img src={Push} className="max-w-full h-auto" /> */}
              <h3 className="text-2xl font-bold  text-teal-500 mb-4">
                PLATFORM STABILITY IS CRUCIAL
              </h3>
              <p>
                Ensure seamless operational continuity with our comprehensive
                backup and disaster recovery approach, safeguarding your
                business's future.
              </p>
            </div>
            <div className="p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-teal-500 mb-4">
                MINIMIZE OPERATIONAL DOWNTIME
              </h3>
              <p>
                Rapid recovery, minimal impact. Our solutions safeguard
                continuity, ensuring your business thrives through any
                disruption.
              </p>
            </div>
            <div className="p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-teal-500 mb-4">
                ROBUST BACKUP STRATEGY
              </h3>
              <p>
                Secure your data, secure your peace of mind. Protect against
                data loss with a backup Defend data integrity with our
                comprehensive backup strategy, blending regularity and
                automation for steadfast security.
              </p>
            </div>
            <div className="p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-teal-500 mb-4">
                MEET AND EXCEED CUSTOMER EXPECTATIONS
              </h3>
              <p>
                Uphold trust with our resilient backup-disaster protocols,
                delivering beyond expectations for data security and system
                uptime.
              </p>
            </div>
            <div className="p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-teal-500 mb-4">
                ENSURE REGULATORY COMPLIANCE
              </h3>
              <p>
                Align with compliance effortlessly. Our backup-disaster
                solutions seamlessly integrate regulatory standards,
                safeguarding against infractions.
              </p>
            </div>
            <div className="p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-teal-500 mb-4">
                READINESS AND RECOVERY
              </h3>
              <p>
                Quick recovery, minimal loss. Our meticulous planning ensures
                your readiness for swift, effective data and service
                restoration.
              </p>
            </div>
          </section>
        </main>
      </main>

      {/* main3 */}
      <main className="mt-14 mb-14 opacity-[0.9]">
        <main className="container max-w-7xl mx-auto bg-gray-100 text-xl text-gray-700 font-bold px-4 py-2 mb-2 rounded-lg border opacity-[0.9]">
          <div className="text-center ">
            <h2 className="text-5xl font-bold text-zinc-700  mb-8 mt-8">
              FEATURED SERVICES
            </h2>
            <p className="font-bold font-sans text-4xl  text-zinc-600 mt-4 mb-4">
              Your Partners in Resilience and Continuity
            </p>
            <p className="font-bold font-sans text-2xl text-zinc-700 mt-4 mb-8 ">
              Discover how The Scale Factory can help you develop a
              best-in-class backup strategy and a bulletproof disaster recovery
              plan
            </p>
          </div>
          <section className=" text-lg font-bold grid text-zinc-700 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div className="p-6 ">
              <div className="flex justify-center items-center" />
              <svg
                className="h-32 w-96 text-zinc-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                />
              </svg>
              <br />
              <h3 className="text-2xl font-bold text-zinc-700  mb-4">
                BACKUP SERVICES
              </h3>
              <p>
                Guaranteed data security and recovery. Our centralized backup
                services offer reliability at a fixed cost, prioritizing your
                business focus.
              </p>
              {/* <a
                href="#"
                className="inline-block mt-8 bg-zinc-700 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded"
              >
                Learn More
              </a> */}
            </div>
            <div className="p-6 rounded-lg ">
              <svg
                className="h-32 w-96 text-zinc-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <polygon points="12 2 2 7 12 12 22 7 12 2" />{" "}
                <polyline points="2 17 12 22 22 17" />{" "}
                <polyline points="2 12 12 17 22 12" />
              </svg>
              <br />
              <h3 className="text-2xl font-bold text-zinc-700  mb-4">
                BACKUP & DISASTER RECOVERY REVIEW
              </h3>
              <p>
                Ensure continuity with our customized backup and disaster
                recovery review, tailored to your business and compliance needs.
              </p>
              {/* <a
                href="#"
                className="inline-block mt-8  bg-zinc-700 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded"
              >
                Learn More
              </a> */}
            </div>

            <div className="p-6 rounded-lg">
              <svg
                className="h-32 w-96 text-zinc-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <polygon points="12 2 2 7 12 12 22 7 12 2" />{" "}
                <polyline points="2 17 12 22 22 17" />{" "}
                <polyline points="2 12 12 17 22 12" />
              </svg>
              <br />
              <h3 className="text-2xl font-bold text-zinc-700  mb-4">
                CRISIS MANAGEMENT PLANNING
              </h3>
              <p>
                Proactive crisis management for assured business stability. Our
                strategic planning keeps your operations consistent and
                prepared.
              </p>
              {/* <a
                href="#"
                className="inline-block mt-16 bg-zinc-700  hover:bg-teal-600 text-white font-bold py-2 px-4 rounded"
              >
                Learn More
              </a> */}
            </div>
            <div className="p-6 rounded-lg">
              <svg
                className="h-32 w-96 text-zinc-600"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />{" "}
                <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2" />
              </svg>
              <br />
              <h3 className="text-2xl font-bold text-zinc-700  mb-4">
                BACKUP & DISASTER RECOVERY REVIEW
              </h3>
              <p>
                Ensure continuity with our customized backup and disaster
                recovery review, tailored to your business and compliance needs.
              </p>
              {/* <a
                href="#"
                className="inline-block mt-8  bg-zinc-700 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded"
              >
                Learn More
              </a> */}
            </div>
          </section>
        </main>
      </main>
    </>
  );
}

export default BachupDisaster
