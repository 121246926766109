import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Bars4Icon,
  GlobeAmericasIcon,
  NewspaperIcon,
  PhoneIcon,
  RectangleGroupIcon,
  SquaresPlusIcon,
  SunIcon,
  TagIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { Link, useNavigate } from "react-router-dom";
import LogoImage from "../assets/carousel/cloudifiers-logo-mavi-saydam-02.png";

const navListMenuItems = [
  {
    title: "Security",
    description: "",
    icon: SquaresPlusIcon,
    link: "/security",
  },
  {
    title: "Build AWS Infrastructure",
    description: "",
    icon: UserGroupIcon,
    link: "/buildAws",
  },
  {
    title: "Backup-Disaster",
    description: "",
    icon: Bars4Icon,
    link: "/bachupDisaster",
  },
  {
    title: "Cost Review",
    description: "",
    icon: SunIcon,
    link: "/constReview",
  },
  {
    title: "Storage Solutions",
    description: "",
    icon: GlobeAmericasIcon,
    link: "/storageSolutions",
  },
  {
    title: "Fullstack Design",
    description: "",
    icon: PhoneIcon,
    link: "/fullstack",
  },
  {
    title: "Cloud Migration",
    description: "",
    icon: NewspaperIcon,
    link: "/cloudMig",
  },
  {
    title: "Performance Optimisation",
    description: "",
    icon: RectangleGroupIcon,
    link: "/performance",
  },
  {
    title: "AWS Well-Architected Review",
    description: "",
    icon: TagIcon,
    link: "/awsWellAr",
  },
];

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = navListMenuItems.map(
    ({ icon, title, description, link }, key) => (
      // eslint-disable-next-line no-undef
      <Link to={link} className="font-medium " key={key}>
        <MenuItem className=" flex items-center gap-3 ">
          <div className="flex items-center justify-center  !bg-teal-500 p-2 rounded ">
            {" "}
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-white w-6",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color=""
              className="flex items-center text-sm font-bold text-slate-600 hover:text-red-500"
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium text-blue-gray-500"
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </Link>
    )
  );

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-3 p-1 pr-4 font-semibold text-lg text-teal-500 bg-gray-950 hover:text-white"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              SERVICES
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block  ">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden ">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList() {
  const navigate = useNavigate();

  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography
        as={Link}
        to="/"
        variant="small"
        color="teal"
        className="font-semibold text-lg hover:text-white"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-6">HOME</ListItem>
      </Typography>
      <NavListMenu />
      <Typography
        as={Link}
        to="/about"
        variant="small"
        color="teal"
        className="font-semibold text-lg hover:text-white"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-6">
          ABOUT US
        </ListItem>
      </Typography>
      <Typography
        as={Link}
        to="/workWithUs"
        variant="small"
        color="teal"
        className="font-semibold text-lg hover:text-white"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-6">
          WORK WITH US
        </ListItem>
      </Typography>
      <Typography
        as="div"
        variant="small"
        color="teal"
        className="font-semibold text-lg hover:text-white"
      >
        <ListItem
          className="flex items-center gap-2 py-2 pr-6"
          onClick={() => navigate("/contact")}
        >
          CONTACT
        </ListItem>
      </Typography>
      <Typography
        as="div"
        variant="small"
        color="teal"
        className="font-semibold text-lg hover:text-white"
      >
        <ListItem
          className="flex items-center gap-2 py-2 pr-6"
          onClick={() => navigate("/insight")}
        >
          INSIGHT
        </ListItem>
      </Typography>
    </List>
  );
}

export function NavbarWithMegaMenu() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <Navbar className=" bg-black mx-auto max-w-full border-hidden rounded-none ">
      <div className="flex items-center justify-around text-red-200">
        <img
          src={LogoImage}
          alt="MK-AWS Logo"
          className="mr-4  cursor-pointer lg:ml-2"
          style={{ maxWidth: "80px" }} // İsteğe bağlı olarak genişliği ayarlayabilirsiniz
        />
        {/* <Typography
          as="a"
          href="#"
          variant="h7"
          className=" cursor-pointer py-2 lg:ml-2 font-bold text-4xl text-gray-200"
        >
          CLOUDIFİERS
        </Typography> */}

        <div className="hidden lg:block">
          <NavList />
        </div>
        {/* <div className="hidden gap-3 lg:flex">
          <Button variant="text" size="sm" color="blue-gray">
            Log In
          </Button>
          <Button variant="gradient" size="sm">
            Sign In
          </Button>
        </div> */}
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
        {/* <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden ">
          <Button variant="outlined" size="sm" color="blue-gray" fullWidth>
            Log In
          </Button>
          <Button variant="gradient" size="sm" fullWidth>
            Sign In
          </Button>
        </div> */}
      </Collapse>
    </Navbar>
  );
}
