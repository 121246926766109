/* eslint-disable jsx-a11y/heading-has-content */

import CarouselComponent from "../components/CarouselComponent";
import Main from "../components/Main";

const Home = () => {
  return (
    <>
      <CarouselComponent />
      <Main />
    </>
  );
};

export default Home;
