
import AwsWellAr from "../services/AwsWellAr"
import FullStack from "../services/FullStack"

const Services = () => {
  return (
    <div>
      Services
      <FullStack/>
      <AwsWellAr/>
    </div>
  );
};

export default Services;
